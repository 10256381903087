<script lang="jsx">

import { Skeleton, SkeletonItem } from '@shein/sui-mobile'

export default {
  name: 'SkeletonItem',
  functional: true,
  components: { Skeleton, SkeletonItem },
  props: {
    // tab tab列
    // line 分割线
    // picNav 图文导航 
    // filter 筛选栏
    // cloudTag 标签云
    // goodsOne 单列商品
    // goodsDouble 双列商品
    // goodsDoubleCategory 双列商品-收藏品类视图
    type: {
      type: String,
      default: ''
    },
    // 商品项骨架屏使用，渲染几个
    number: {
      type: [Number, String],
      default: 4
    }
  },
  render(h, { props, data }) {
    const line = () => {
      return (
        <div class="skeleton-item-line">
          <Skeleton animated>
            <template slot="template">
              <SkeletonItem style="width:100%;height:.266rem;" />
            </template>
          </Skeleton>
        </div>
      )
    }

    const tab = () => {
      return (
        <div class="skeleton-item-tab">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: 6 }, () => (
                  <SkeletonItem style="width:2rem;height:.64rem;margin-right:.213rem;" />
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }

    const picNav = () => {
      return (
        <div class="skeleton-item-picNav">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: 6 }, () => (
                  <SkeletonItem style="width:1.39rem;height:1.95rem;margin-right:.213rem;" />
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }

    const filter = () => {
      return (
        <div class="skeleton-item-filter">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: 4 }, () => (
                  <SkeletonItem style="width:2.02rem;height:.64rem;" />
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }

    const cloudTag = () => {
      return (
        <div class="skeleton-item-cloudTag">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: 5 }, () => (
                  <SkeletonItem style="width:1.97rem;height:.72rem;margin-right:.213rem;" />
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }

    const goodsOne = () => {
      return (
        <div class="skeleton-item-goodsOne">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: props.number }, () => (
                  <div class="goods-container">
                    <SkeletonItem style="width:2.56rem;flex:0 0 2.56rem;height:3.46rem;border-radius:.06rem;"  />

                    <div class="goods-info">
                      <div class="goods-info__top">
                        <SkeletonItem style="width:6.48rem;height:.53rem;" />
                        <SkeletonItem style="width:3.2rem;height:.53rem;" />
                      </div>

                      <div class="goods-info__bottom">
                        <SkeletonItem style="width:5.173rem;height:.37rem;" />
                        <SkeletonItem style="width:.53rem;height:.53rem;" />
                      </div>
                    </div>
                  </div>
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }

    const goodsDouble = () => {
      return (
        <div class="skeleton-item-goodsDouble">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: props.number }, () => (
                  <div class="goods-container">
                    <SkeletonItem style="width:4.77rem;height:6.4rem;border-radius:.11rem" />

                    <div class="goods-info">
                      <SkeletonItem style="width:4.35rem;height:.37rem" />

                      <div class="goods-info__top">
                        <SkeletonItem style="width:3.28rem;height:.53rem" />
                        <SkeletonItem style="width:.96rem;height:.64rem;border-radius:.32rem" />
                      </div>

                      <div class="goods-info__bottom">
                        <SkeletonItem style="width:1.36rem;height:.37rem;border-radius:0.11rem" />
                        <SkeletonItem style="width:1.36rem;height:.37rem;border-radius:0.11rem" />
                        <SkeletonItem style="width:1.36rem;height:.37rem;border-radius:0.11rem" />
                      </div>
                    </div>
                  </div>
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }

    const goodsDoubleCategory = () => {
      return (
        <div class="skeleton-item-goodsDoubleCategory">
          <Skeleton animated>
            <template slot="template">
              {
                Array.from({ length: props.number }, () => (
                  <div class="goods-container">
                    <SkeletonItem style="width:3.36rem;height:3.36rem;border-radius:.11rem" />

                    <div class="goods-info">
                      <div class="goods-info__top">
                        <SkeletonItem style="width:1.7rem;height:.53rem" />
                        <SkeletonItem style="width:.59rem;height:.53rem;float:right;" />
                      </div>
                      <SkeletonItem style="width:3.36rem;height:.37rem" />
                    </div>
                  </div>
                ))
              }
            </template>
          </Skeleton>
        </div>
      )
    }


    // line 分割线
    // picNav 图文导航 
    // filter 筛选栏
    // cloudTag 标签云
    // goodsOne 单列商品
    // goodsDouble 双列商品
    // goodsDoubleCategory 双列商品-收藏品类视图
    const typeRenderMap = new Map([
      ['line', line],
      ['picNav', picNav],
      ['filter', filter],
      ['cloudTag', cloudTag],
      ['goodsOne', goodsOne],
      ['goodsDouble', goodsDouble],
      ['goodsDoubleCategory', goodsDoubleCategory],
      ['tab', tab]
    ])

    const typeRes = props.type && typeRenderMap.get(props.type)()
    return (
      <div 
        class={[
          'skeleton-item',
          data.class,
          data.staticClass
        ]}

        style={[
          data.style,
          data.staticStyle
        ]}
      >
        { typeRes }
      </div>
    )
  }
}

</script>

<style lang="less" scoped>
.skeleton-item {
  background: #fff;
  line-height: 1;
}

.skeleton-item-tab {
  .flexbox();
  .align-center();
  padding-left: .32rem;
  height: 1.12rem;

  /deep/ .S-skeleton-wrap {
    .flexbox();
    .align-center();
    flex-wrap: nowrap;
  }
}

.skeleton-item-picNav {
  .flexbox();
  .align-center();

  flex-wrap: nowrap;

  padding-left: .32rem;
  height: 2.59rem;
}

.skeleton-item-filter {
  .flexbox();
  .align-center();
  height: 1.173rem;

  /deep/ .S-skeleton-wrap {
    .flexbox();
    .align-center();
    justify-content: space-around;
  }
}

.skeleton-item-cloudTag {
  .flexbox();
  flex-wrap: nowrap;

  padding-left: .16rem;
  height: .934rem;

  /deep/ .S-skeleton-wrap {
    .flexbox();
    .align-center();
    justify-content: space-around;
  }
}

.skeleton-item-goodsOne {
  .goods-container {
    .flexbox();
    padding: 0 .32rem .746rem .32rem;
  }

  .goods-info {
    .flexbox();
    flex-direction: column;
    justify-content: space-between;

    padding-left: .32rem;

    &__top {
      &>div:first-child {
        margin-bottom: .32rem;
      }
    }

    &__bottom {
      .flexbox();
      .align-center();
      justify-content: space-between;

    }
  }
}

.skeleton-item-goodsDouble {
  padding: 0 .16rem;

  /deep/ .S-skeleton-wrap {
    .flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .goods-container {
    padding-bottom: .427rem;
  }
  .goods-info {
    padding: .16rem .213rem 0 .213rem;

    &__top {
      padding: .106rem 0 .213rem 0;
      .flexbox();
      justify-content: space-between;
    }

    &__bottom {
      .flexbox();
      justify-content: space-between;

    }
  }
}

.skeleton-item-goodsDoubleCategory {
  /deep/ .S-skeleton-wrap {
    padding: 0 0.16rem;
    .flexbox();
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>
